<template>
  <section class="select-role-section">
    <b-card>
      <h2
        v-if="isMentorApplicationsOpen || isMenteeApplicationsOpen"
        class="text-center mb-3 mt-2"
      >
        Select role
      </h2>
      <h2 v-else>
        Applications have closed for this program
      </h2>

      <b-form-radio-group
        id="radio-group-2"
        v-model="role"
        name="select-a-role"
      >
        <b-row>
          <b-col
            v-if="isMentorApplicationsOpen"
            :sm="isMentorApplicationsOpen && isMenteeApplicationsOpen ? 4 : 6"
            :offset="isMenteeApplicationsOpen ? 0 : 3"
          >
            <b-card
              class="border align-items-center flex-column"
              no-body
            >
              <b-avatar
                size="lg"
                class="mt-3 mb-1"
              >
                <feather-icon
                  icon="UserIcon"
                  size="52"
                  stroke-width="1"
                  fill="white"
                />
              </b-avatar>
              <p class="mb-1">
                I want to be a
              </p>
              <h4 class="mb-1">
                <b>{{ mentorDisplay }}</b>
              </h4>
              <b-form-radio
                :value="[{role_id: userRoles.MENTOR}]"
                aria-describedby="Mentor role"
                name="mentor-role"
                class="mb-2"
              />
            </b-card>
          </b-col>
          <b-col
            v-if="isMenteeApplicationsOpen"
            :sm="isMenteeApplicationsOpen && isMentorApplicationsOpen ? 4 : 6"
            :offset="isMentorApplicationsOpen ? 0 : 3"
          >
            <b-card
              class="border align-items-center flex-column"
              no-body
            >
              <b-avatar
                size="lg"
                class="mt-3 mb-1 white"
              >
                <feather-icon
                  icon="UserIcon"
                  size="52"
                  stroke-width="1"
                  fill="white"
                />
              </b-avatar>
              <p class="mb-1">
                I want to be a
              </p>
              <h4 class="mb-1">
                <b>{{ menteeDisplay }}</b>
              </h4>
              <b-form-radio
                :value="[{role_id:userRoles.MENTEE}]"
                aria-describedby="Mentee role"
                name="mentee-role"
                class="mb-2"
              />
            </b-card>
          </b-col>

          <b-col
            v-if="multipleRolesEnabled && isMentorApplicationsOpen && isMenteeApplicationsOpen"
            sm="4"
          >
            <b-card
              class="border align-items-center flex-column"
              no-body
            >
              <span>
                <b-avatar
                  size="lg"
                  class="mt-3 mb-1 mr-50"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="52"
                    stroke-width="1"
                    fill="white"
                  />
                </b-avatar>
                <b-avatar
                  size="lg"
                  class="mt-3 mb-1 white ml-50"
                >
                  <feather-icon
                    icon="UserIcon"
                    size="52"
                    stroke-width="1"
                  />
                </b-avatar>
              </span>
              <p class="mb-1">
                I want to be a
              </p>
              <h4 class="mb-1">
                <b> {{ mentorDisplay }} &amp; {{ menteeDisplay }}</b>
              </h4>
              <b-form-radio
                :value="[{role_id:userRoles.MENTEE}, {role_id:userRoles.MENTOR}]"
                aria-describedby="Mentee Mentor role"
                name="mentee-mentor-role"
                class="mb-2"
              />
            </b-card>
          </b-col>
          <b-col 
            v-if="showLanguagesList"
            sm="12"
            class="justify-content-center"
          >
            <h6 class="text-center mb-1 mt-2">
              Select language
            </h6>
          </b-col>
          <b-col
            v-if="showLanguagesList"
            sm="12"
            class="d-flex justify-content-center"
          >
            <v-select
              id="preferred-language"
              v-model="languageId"
              item-text="name"
              item-value="id"
              label="name"
              :options="languageList"
              :reduce="i => i.id"
              :clearable="false"
            />
          </b-col>
          <b-col
            v-if="isMenteePerMentorDisplayed"
            sm="12"
            class="d-flex justify-content-center"
          >
            <h6 class="text-center mb-1 mt-2">
              How many {{ menteeDisplay }}s would you like to {{ mentorDisplay }}?
            </h6>
          </b-col>
          <b-col
            v-if="isMenteePerMentorDisplayed"
            sm="12"
            class="d-flex justify-content-center"
          >
            <div class="d-flex justify-content-center">
              <v-select
                v-model="menteePerMentor"
                placeholder="Event"
                :options="menteePerMentorOptions"
                label="name"
              />
            </div>
          </b-col>
          <b-col
            v-if="isMentorApplicationsOpen || isMenteeApplicationsOpen"
            sm="12"
            class="d-flex justify-content-center"
          >
            <div class="d-flex justify-content-center">
              <b-button
                class="mb-1 mt-2"
                variant="outline-primary"
                size="md"
                :disabled="isDisabled"
                @click="onSubmit"
              >
                <b-spinner
                  v-if="isUpdating" 
                  small
                />
                <span
                  v-if="isUpdating"
                > Updating...</span>
                <span v-else>Continue</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form-radio-group>
    </b-card>
  </section>
</template>
<script>
import { locales, localesDisplay, userRoles, UserRolesClass } from "@/models";
import { usersService, applicationService, authService } from "@/services";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BAvatar,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import store from '@/store';

export default {
  name: 'SelectProgramRole',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BAvatar, 
    BCard,
    BFormRadio,
    BFormRadioGroup,
    vSelect,

  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      const user = vm.profile;
      const hasParticipantRole = user.roles?.includes(userRoles.MENTEE) || user.roles?.includes(userRoles.MENTOR);
      if(hasParticipantRole) {
        vm.$router.push({name: 'participant-home'});
      }
    });
  },
  data() {
    return {
      role: [],
      menteePerMentor:1,
      isUpdating: false,
      programApplications: [],
      userRoles,
      languageId: locales.EN
    };
  },
  computed: {
    ...mapGetters('participants', ['mentorDisplay', 'menteeDisplay']),
    ...mapGetters("app", ["currentProgram"]),
    ...mapGetters('profile',['profile']),
    isActiveRoleMentee () {
      return this.role.find(r => r.role_id === userRoles.MENTEE) && this.role.length === 1;
    },
    isActiveRoleMentor () {
      return this.role.find(r => r.role_id === userRoles.MENTOR) && this.role.length === 1;
    },
    isActiveRoleBoth () {
      return this.role.find(r => r.role_id === userRoles.MENTOR) && this.role.find(r => r.role_id === userRoles.MENTEE);
    },
    isDisabled() {
      return this.role.length === 0 || ((this.isActiveRoleMentor || this.isActiveRoleBoth) && this.menteePerMentor === 0);
    },
    isMenteePerMentorDisplayed() {
      return (this.isActiveRoleBoth || this.isActiveRoleMentor) && this.mentorMatchLimit > 1;
    },
    participantCurrentProgram () {
      return this.$store.getters['participants/program'];
    },
    multipleRolesEnabled() {
      return this.participantCurrentProgram.multiple_roles_enabled;
    },
    mentorMatchLimit() {
      const role = this.participantCurrentProgram.program_roles.find(pr => pr.role_id === userRoles.MENTOR);
      return role ? role.match_limit : 10;
    },
    isMentorApplicationsOpen () {
      const regStatus = this.participantCurrentProgram.registration_status.applications.filter(r => r.role_id === userRoles.MENTOR);
      return regStatus[0]?.is_open;
    },
    isMenteeApplicationsOpen () {
      const regStatus = this.participantCurrentProgram.registration_status.applications.filter(r => r.role_id === userRoles.MENTEE);
      return regStatus[0]?.is_open;
    },
    mentorApplication () {
      return this.participantCurrentProgram?.application_set?.applications.filter(a => a.roles[0].id === userRoles.MENTOR);
    },
    menteeApplication () {
      return this.participantCurrentProgram?.application_set?.applications.filter(a => a.roles[0].id === userRoles.MENTEE);
    },
    menteePerMentorOptions () {
      return Array.from({length: this.mentorMatchLimit}, (_, i) => i + 1);
    },
    languageList() {
      const langs = this.currentProgram?.languages?.slice(0) || [];
      if (!langs.find(l => l.id === locales.EN)) {
        langs.unshift({
          id: locales.EN,
          name: localesDisplay[locales.EN]
        });
      }
      return langs;
    },
    showLanguagesList() {
      return this.languageList.length > 1;
    }
  },
  watch: {
    menteePerMentor(n) {
      n = Math.max(1, Math.min(this.mentorMatchLimit, Math.round(n)));
      if(n) {
        this.role = this.role.map(r => r.role_id === userRoles.MENTOR ? ({...r, match_limit: Number(n)}) : r);
      }
      this.menteePerMentor = n;
    }
  },
  methods: {
    onPlusMenteePerMentorClick() {
      this.menteePerMentor = Math.min(this.mentorMatchLimit, this.menteePerMentor + 1);
    },
    onMinusMenteePerMentorClick() {
      this.menteePerMentor = Math.max(1, this.menteePerMentor - 1);
    },
    selectMentee() {
      this.role = Array.from([{role_id: userRoles.MENTEE}]);
    },
    selectMentor() {
      this.role = Array.from([{role_id: userRoles.MENTOR, match_limit: this.menteePerMentor}]);
    },
    selectBoth () {
      this.role = Array.from([{role_id: userRoles.MENTEE}, {role_id: userRoles.MENTOR, match_limit: this.menteePerMentor}]);
    },
    async onSubmit() {
      await this.saveRole();
      await store.dispatch('participants/FETCH_CURRENT_PROGRAM');
      await this.loadApplications();
      this.setUserRoleInStore();
      this.routeUserToApplication();
    },
    async saveRole() {
      try {
        this.isUpdating = true;
        await authService.updateUserProfile({preferred_language_id: this.languageId});
        const programId = this.currentProgram.id;
        await usersService.selectParticipantRoles(programId, {user_roles:this.role});
        this.$toast(makeSuccessToast('User updated successfully.'));
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast('User update failed.'));
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    setUserRoleInStore() {
      let user = this.profile;
      user.preferred_language_id = this.languageId;
      user.preferred_language = this.languageList.find(l => l.id === this.languageId);
      const newRoles = this.role.map(r => new UserRolesClass({
        role_id: r.role_id,
        match_limit: r.match_limit,
        program_id:  this.currentProgram.id,
      }));
      const newRoleIds = this.role.map(r => r.role_id);
      const highestRole = this.getHighestParticipantRole();
      user = {
        ...user,
        role: highestRole,
        roles: [
          ...user.roles,
          ...newRoleIds
        ],
        user_roles: [
          ...user.user_roles,
          ...newRoles
        ]
      };
      
      this.$store.commit('profile/SET_USER_PROFILE', user);
      this.$store.commit('profile/SET_USER_ROLE', user.role);

    },
    getHighestParticipantRole () {
      if (this.isActiveRoleMentee) { 
        return userRoles.MENTEE; 
      }
      if (this.isActiveRoleMentor || this.isActiveRoleBoth) { 
        return userRoles.MENTOR; 
      }
    },
    routeUserToApplication () {
      if (this.isActiveRoleMentee) { 
        const menteeApp = this.programApplications.filter(app => app.roles[0]?.id === userRoles.MENTEE);
        const questionId = menteeApp[0]?.questions[0].id;
        if(questionId) {
          this.$router.replace({ name : 'participant-application-mentee', params: { role: userRoles.MENTEE, questionId }, query: {'enrolement' : true}});
          return;
        }
        this.$toast(makeErrorToast('Unable to route to applications'));
      }
      if (this.isActiveRoleMentor || this.isActiveRoleBoth) { 
        const mentorApp = this.programApplications.filter(app => app.roles[0]?.id === userRoles.MENTOR);
        const questionId = mentorApp[0]?.questions[0].id;
        this.$router.replace({ name : 'participant-application-mentor', params: { role: userRoles.MENTOR, questionId }, query: {'enrolement' : true}});
        return;
      }
    },
    async loadApplications () {
      try {
        const programId = this.participantCurrentProgram.id;
        const applicationSetId = this.participantCurrentProgram?.application_set?.id;
        const response = await applicationService.getApplicationSets(programId, applicationSetId);
        const { data } = response;
        this.programApplications = data?.applications;
      } catch(e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#preferred-language {
  width: 160px;
}
.badge-secondary {
  background: #eeecec;
  border: 2px solid #403f3f;
}
.b-avatar {
  color: #403f3f;
}
.badge-secondary.white { 
  background: #FFF;
  border: 2px solid #403f3f;
}
</style>
  